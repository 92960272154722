<template>
    <div>
        <main>
            <!-- Embed PDF -->
            <iframe :src="pdfSource" width="100%" height="800px" frameborder="0">
                Your browser does not support PDFs.
            </iframe>
            <!-- <p>Your browser does not support PDFs.
                <a :href="pdfSource" download>Download the PDF</a> to view it.
            </p> -->

            <!-- Download Button -->
            <div class="download-container">
                <a :href="pdfSource" :download="downloadFilename" class="download-btn">Download Resume</a>
            </div>
        </main>
        <footer>
            <p>© 2024 Whatley Julitar. All rights reserved. |
                <a href="https://whatley.xyz" target="_blank">whatley.xyz</a>
            </p>
        </footer>
    </div>
</template>

<script>
export default {
    name: "ResumeViewer",
    data() {
        return {
            pdfVersion: "20241208v002", // Dynamic version control
        };
    },
    computed: {
        pdfSource() {
            // Update path to point to the "resume" folder inside the public directory
            return `/resume/Whatley-Julitar-${this.pdfVersion}.pdf`;
        },
        downloadFilename() {
            // Suggest file name for downloading
            return `Whatley_Resume_${this.pdfVersion}.pdf`;
        },
    },
};
</script>

<style scoped>
footer {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    color: #555;
}

footer a {
    color: #007acc;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

.download-container {
    margin-top: 10px;
    text-align: center;
}

.download-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007acc;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
}

.download-btn:hover {
    background-color: #005b99;
}
</style>
