<template>
  <div id="app">
    <ResumeViewer />
  </div>
</template>

<script>
import ResumeViewer from './components/ResumeViewer.vue';

export default {
  name: 'App',
  components: {
    ResumeViewer,
  },
};
</script>
